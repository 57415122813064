<template>
  <div class="accordion">
    <div class="title jc-between ai-center" @click="togglePanel()" :class="{ active: isActive }">
      <span>{{ name || item.name }}</span>
      <div class="padding-top" ref="mainPanel">
        <VLottie ref="animation" :src="require('@/assets/icons/dropdown.json')" />
        <VButton v-if="showDeleteDegrado" @click.stop="deleteDegrado" class="del-degrado"
          :icon="require('@/assets/icons/cross.svg')" :iconSize="7" />
      </div>
    </div>
    <div class="panel" ref="panel">
      <slot></slot>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';

const props = defineProps({
  item: Object,
  index: Number,
  name: String,
  autoHeight: Boolean,
  showDeleteDegrado: {
    type: Boolean,
    default: false
  },
  autoScroll: {
    type: Boolean,
    default: true
  },
  isOpen: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['resize', 'deleteDegrado', 'opened', 'update:isOpen'])

const isActive = ref(false)
const animation = ref(null)
const panel = ref(null)
const mainPanel = ref(null)

watch(() => props.isOpen, (newVal) => {
  isActive.value = newVal;
  if (newVal) {
    openPanel();
    if(props.autoScroll) {
      mainPanel.value.scrollIntoView({ behavior: 'smooth' });
    }
  } else {
    closePanel();
  }
});

const togglePanel = () => {
  emit('opened', props.index)
  if (isActive.value) {
    closePanel()
  } else {
    openPanel()
  }
  isActive.value = !isActive.value;
}


const openPanel = () => {
  emit('resize')
  if (animation.value) {
    animation.value.playSegments([5, 25], true)
  }
  setPanelHeight()
}

const closePanel = () => {
  if (animation.value) {
    animation.value.playSegments([30, 50], true)
  }
  resetPanelHeight()
}

const setPanelHeight = () => {  
  panel.value.style.maxHeight = 143 + "px";
  setTimeout(() => { 
    if(panel.value) {
      panel.value.style.maxHeight = "unset";
    } 
  }, 200)
}

const resetPanelHeight = () => {
  panel.value.style.maxHeight = panel.value.scrollHeight + "px";
  setTimeout(() => {
    if(panel.value) {
      panel.value.style.maxHeight = null; 
    }
  }, 50)
}

const deleteDegrado = () => {
  emit('deleteDegrado')
}
</script>

<style scoped>
.accordion {
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
}

.accordion>div {
  flex-direction: column;
  display: block;
}

.title>div>div {
  width: 20px;
}

.title>div {
  margin-right: 15px;
}

.accordion .title {
  display: flex;
  flex-direction: row;
  height: 42px;
  letter-spacing: 0.01em;
  font-size: .8125rem;
  cursor: pointer;
  padding-left: 15px;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  border: none;
  text-align: left;
  transition: 0.4s;
  background-color: var(--base-light-color);
  color: var(--secondary-color);
}

.panel {
  /* padding: 0 6px; */
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease;
}

.padding-top {
  padding-top: 10px;
}

.panel p {
  padding-bottom: 20px;
}

.accordion .title:hover .v-button.del-degrado {
  padding: 5px;
  max-width: 30px;
  transition: all .3s ease .1s;
}

.accordion .title .v-button.del-degrado {
  transition: all .3s ease;

  /* opacity: 0; */
  /* display: none; */
  background-color: white;
  max-width: 0px;
  padding: 0;
  overflow: hidden;
  border-radius: 50%;
  /* position: absolute; */
  right: -18px;
  top: 3px;
  border: 2px solid #d9d9d9;
  margin: 0 0 0 5px;
}
</style>
import * as turf from '@turf/turf'

export const POLYGON_STYLES = {
    background: {
        color: '#706C52',
        fillColor: '#706C52',
        fillOpacity: 0.3,
        weight: 1,
    },
    foreground: {
        color: '#E8831C',
        fillColor: '#E8831C',
        fillOpacity: 0.3,
        weight: 1,
    },
    selected: {
        color: '#E8831C',
        fillColor: '#E8831C',
        fillOpacity: 1,
        opacity: 1,
        weight: 5
    },
    transparent: {
        color: '#706C52',
        fillColor: '#706C52',
        fillOpacity: 0,
        opacity: 0,
        weight: 1,
    },
    region: {
        fillColor: "#929085",
        color: "#929085",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.4,
    },
    insula: {
        fillColor: "#929085",
        color: "#929085",
        weight: 3,
        opacity: 0.8,
        fillOpacity: 0.4,
    },
    unity: {
        fillColor: "#929085",
        color: "#929085",
        weight: 2,
        opacity: 0.6,
        fillOpacity: 0.4,
    },
    environment: {
        fillColor: "#929085",
        color: "#929085",
        weight: 1,
        opacity: 0.4,
        fillOpacity: 0.3,
    },
    topographicElement4: {
        fillColor: "#929085",
        color: "#929085",
        weight: 2,
        opacity: 0.4,
        fillOpacity: 0.3,
    },
    topographicElement4Vertical: {
        color: "#929085",
        weight: 8,
        opacity: 1,
        fillOpacity: 1
    },
    circleMarker: {
        fillOpacity: 1,
        color: "#ffffff",
        opacity: 1,
        radius: 6,
        weight: 1
    }
}

export function geojsonToTurf(geojson) {
    // if geojson is null, throw an error
    if (geojson === null) {
        throw new Error('geojson is null')
    }

    // if geojson is a string, parse it
    if (typeof geojson === 'string') {
        geojson = JSON.parse(geojson)
    }

    // if geojson is a point, return a turf point
    if (geojson.geometry?.type === 'Point') {
        return turf.point(geojson.geometry.coordinates)
    }

    if (geojson.type === 'MultiPolygon' || geojson.geometry?.type == 'MultiPolygon') {
        let startingRoot = geojson.type === 'MultiPolygon' ? geojson.coordinates[0][0] : geojson.geometry.coordinates[0][0]
        return turf.multiPolygon([[startingRoot]])
    }

    if (geojson.type === 'MultiLineString' || geojson.geometry?.type == 'MultiLineString') {
        let startingRoot = geojson.type === 'MultiLineString' ? geojson.coordinates[0] : geojson.geometry.coordinates[0]
        return turf.multiLineString([startingRoot])
    }
}

export const MAP_LAYERS = [
    {
        'group:': true,
        'layers': [
            {
                'url': 'https://d3bvprgq17y0qm.cloudfront.net/tilesets/archaeological_map/{z}/{x}/{y}.png',
                'config': {
                    maxZoom: 24,
                    maxNativeZoom: 23
                }
            }
        ]
    },
    {
        'group:': true,
        'layers': [
            {
                'url': 'https://d3bvprgq17y0qm.cloudfront.net/drone_flights/2024/11/{z}/{x}/{y}.png',
                'config': {
                    maxZoom: 24,
                    maxNativeZoom: 22
                }
            }
        ]
    }
];

export const MAP_CENTER = [40.750858816829336, 14.486042279429101];

export const LEAFLET_CONFIG = {
    minZoom: 17,
    maxZoom: 24,
    rotate: false,
    rotateControl: false,
}
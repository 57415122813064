<template>
  <div class="ref-top ai-center jc-center">
    <div class="popup flex-col gap-20">
      <div class="jc-between">
        <div class="ai-center gap-10">
          <VIcon :src="require('@/assets/icons/ref-top.svg')" />
          <span class="title">Referente topografico</span>
        </div>
        <VButton class="close" :icon="require('@/assets/icons/cross.svg')" @click="close" />
      </div>
      <div class="grid grid-2-col gap-30">
        <div class="grid grid-2-col gap-5" v-if="!loading">
          <VInput class="grid-col-span medium solid" :icon="require('@/assets/icons/search.svg')" :iconSize="15"
            type="text" v-model="searchQuery" />
          <DropDown :options="regionsDropdown" :selected="currentRegion?.id" @select="selectRegion" label="Regioni" />
          <DropDown :options="insulaeDropdown" :selected="currentInsula?.id" @select="selectInsula" label="Insulae" />
          <div class="ref-top-list grid-col-span flex-col">
            <template v-for="(unity, i) in filteredInsulaChildren" :key="i">
              <TheAccordion :index="i" :item="unity" :autoHeight="true" class="grid-col-span sub" @opened="selectUnity"
                :autoScroll="true" :isOpen="currentUnityIndex == i">
                <TheAccordion v-for="(enviroment, j) in currentUnityIndex == i ? unity.children : []" :index="j"
                  :item="enviroment" :key="j" :autoScroll="true" @opened="selectEnvironment"
                  :isOpen="currentEnvironmentIndex == j">
                  <div class="options-box flex-col" v-if="currentEnvironmentIndex == j">
                    <RadioButton v-model="topElId" :value="el.id" :label="el.frontend_title"
                      v-for="(el, i) in currentEnvironment.topographic_element4s" :key="i" />
                  </div>
                </TheAccordion>
                <TheAccordion :item="{ name: 'Altri referenti topografici', children: unity.topographic_element4s }"
                  v-if="unity.topographic_element4s.length > 0" :index="currentUnity?.children?.length"
                  :autoHeight="true" :autoScroll="true" :isOpen="true">
                  <div class="options-box flex-col">
                    <RadioButton v-model="topElId" :value="el.id" :label="el.frontend_title"
                      v-for="(el, i) in unity.topographic_element4s" :key="i" />
                  </div>
                </TheAccordion>

              </TheAccordion>
            </template>
            <!-- if there are folders or leftover at the root level -->
            <TheAccordion
              v-if="currentFilteredTopEls.children?.length > 0 || currentFilteredTopEls.leftover?.length > 0"
              :item="{ name: 'Altri referenti topografici' }" :index="0" class="grid-col-span sub" :autoScroll="true"
              :isOpen="true">
              <!-- 1) iterate over the children of the root level -->
              <template v-for="(folder, idx) in currentFilteredTopEls.children" :key="idx">
                <TheAccordion :item="folder" :isOpen="true">
                  <!-- leftover of the children of the root level -->
                  <div class="options-box flex-col" v-if="folder.leftover?.length">
                    <RadioButton v-for="(el, iEl) in folder.leftover" :key="iEl" v-model="topElId" :value="el.id"
                      :label="el.frontend_title" />
                  </div>

                  <!-- 2) iterate over the children of the children of the root level -->
                  <template v-for="(subFolder, j) in folder.children" :key="j">
                    <TheAccordion :item="subFolder" :isOpen="true">
                      <!-- leftover of the subFolder -->
                      <div class="options-box flex-col" v-if="subFolder.leftover?.length">
                        <RadioButton v-for="(el, k) in subFolder.leftover" :key="k" v-model="topElId" :value="el.id"
                          :label="el.frontend_title" />
                      </div>

                      <!-- 3) third level of folders -->
                      <template v-for="(subSubFolder, h) in subFolder.children" :key="h">
                        <TheAccordion :item="{ name: subSubFolder.name }" :isOpen="true" class="sub-accordion">
                          <div class="options-box flex-col" v-if="subSubFolder.leftover?.length">
                            <RadioButton v-for="(el, y) in subSubFolder.leftover" :key="y" v-model="topElId"
                              :value="el.id" :label="el.frontend_title" />
                          </div>
                        </TheAccordion>
                      </template>
                    </TheAccordion>
                  </template>
                </TheAccordion>
              </template>

              <!-- leftover of the root level -->
              <div class="options-box flex-col" v-if="currentFilteredTopEls.leftover?.length">
                <RadioButton v-for="(el, i) in currentFilteredTopEls.leftover" :key="i" v-model="topElId" :value="el.id"
                  :label="el.frontend_title" />
              </div>
            </TheAccordion>
          </div>
        </div>
        <VLottie class="loading" v-if="loading" ref="animation" :src="require('@/assets/icons/loading.json')"
          :autoplay="true" :loop="true" />
        <div class="flex-col">
          <VMap v-if="regions.length > 0" :polygons="polygons" :geolocate="!startingPosition" :customLayers="MAP_LAYERS"
            :leafletConfig="{ minZoom: 16 }" :zoomDuration="1" :fitBounds="true" @location="filterByLocation"
            :orientation="true" :center="MAP_CENTER" />
          <div class="jc-end act-buttons gap-15">
            <VButton class="medium rounded" label="Cancella" @click="close" />
            <VButton class="medium rounded" label="Salva" @click="save" :disabled="!topElId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DropDown from './DropDown.vue';
import TheAccordion from './TheAccordion.vue';
import RadioButton from './RadioButton.vue';
import { onUnmounted, ref } from 'vue'
import { useUXStore } from '@/stores/ux';

import { getNearestTopographicElement4 } from '@/services/topographicServices';
import { MAP_LAYERS, POLYGON_STYLES, MAP_CENTER, geojsonToTurf } from '@/utils/polygon';
import { customArabicSort, customRomanSort } from '@/utils/utils';
import { getEnvironments, getInsulae, getRegions, getTopographicElements4, getUnities } from '@/services/cacheServices';

export default {
  name: 'RefTopPopUp',
  components: {
    DropDown,
    TheAccordion,
    RadioButton
  },
  props: {
    startingPosition: {
      type: Object,
      default: null,
    },
  },
  watch: {
    topElId(id) {
      this.selectTopographicElement(id)
    }
  },
  computed: {
    regionsDropdown() {
      return this.regions
        ?.slice()
        .sort(customRomanSort)
        .map(poly => {
          return {
            label: poly.name,
            value: poly.id,
          }
        })
    },
    currentFilteredTopEls() {
      return this.currentInsula ? this.filteredCurrentInsulaTopEls : (this.currentRegion ? this.filteredCurrentRegionTopEls : [])
    },
    insulaeDropdown() {
      return this.currentRegion?.children
        ?.slice().sort(customArabicSort)
        .map(insula => {
          return {
            label: insula.name,
            value: insula.id,
          };
        });
    },
    currentUnityIndex() {
      return this.currentInsula?.children?.findIndex(unity => unity.id === this.currentUnity?.id)
    },
    currentEnvironmentIndex() {
      return this.currentUnity?.children?.findIndex(environment => environment.id === this.currentEnvironment?.id)
    },
    /**
     * It filters the unities (and environments + topographic_element4s) by the search query
     */
    filteredInsulaChildren() {
      // if there is no current insula or it has no children, return empty array
      if (!this.currentInsula?.children) return []

      // if searchQuery is empty, return all the children
      if (!this.searchQuery) {
        return this.currentInsula.children
      }

      // else, filter
      const query = this.searchQuery.toLowerCase()

      return this.currentInsula.children
        // 1) we filter at unity level
        .filter(unity => {
          if (unity.name.toLowerCase().includes(query)) {
            return true
          }

          // if the Unity has environments and at least one match, we keep it
          if (unity.children?.some(env => env.name.toLowerCase().includes(query))) {
            return true
          }

          // if the Unity has topographic_element4s and at least one match, we keep it
          if (unity.topographic_element4s?.some(el => el.frontend_title.toLowerCase().includes(query))) {
            return true
          }

          return false
        })
        // 2) the result is mapped to filter the environments inside each unity
        .map(unity => {
          // clone the unity in order to not mutate the original object
          const newUnity = { ...unity }

          // if the Unity has environments, we filter them
          if (newUnity.children) {
            newUnity.children = newUnity.children
              .filter(env => {
                // if the environment name matches the query, we keep it
                if (env.name.toLowerCase().includes(query)) {
                  return true
                }
                // if the environment has topographic_element4s and at least one match, we keep it
                if (env.topographic_element4s?.some(el => el.frontend_title.toLowerCase().includes(query))) {
                  return true
                }
                return false
              })
              // finally, we filter the topographic_element4s inside each environment
              .map(env => {
                const newEnv = { ...env }
                if (newEnv.topographic_element4s) {
                  newEnv.topographic_element4s = newEnv.topographic_element4s
                    .filter(el => el.frontend_title.toLowerCase().includes(query))
                }
                return newEnv
              })
          }

          // filter topographic_element4s
          if (newUnity.topographic_element4s) {
            newUnity.topographic_element4s = newUnity.topographic_element4s
              .filter(el => el.frontend_title.toLowerCase().includes(query))
          }

          return newUnity
        })
    },
    filteredCurrentRegionTopEls() {
      if (!this.currentRegion?.topographic_element4s) return []

      let filtered;

      if (!this.searchQuery) {
        filtered = this.currentRegion.topographic_element4s
      } else {
        const query = this.searchQuery.toLowerCase()
        filtered = this.currentRegion.topographic_element4s.filter(el =>
          el.frontend_title.toLowerCase().includes(query)
        )
      }

      let res = this.organizeInFolders(filtered)
      console.log("filteredCurrentRegionTopEls", res)
      return res
    },
    /**
     * It filters the topographic_element4s by the search query
     */
    filteredCurrentInsulaTopEls() {
      if (!this.currentInsula?.topographic_element4s) return []

      // filter by search query
      let filtered;
      if (!this.searchQuery) {
        filtered = this.currentInsula.topographic_element4s
      } else {
        const query = this.searchQuery.toLowerCase()
        filtered = this.currentInsula.topographic_element4s.filter(el =>
          el.frontend_title.toLowerCase().includes(query)
        )
      }

      return this.organizeInFolders(filtered)
    },
    polygons() {
      console.log("currentPolygons", this.currentPolygons)
      return this.currentPolygons.map(p => { return { ...p, polygon: p.transformedPolygon ?? p.transformed_polygon } })
    }
  },
  mounted() {
    console.log(this)
    this.uxstore.showOverlay = true
    getRegions().then(regions => {
      this.regions = regions
      this.currentPolygons = this.regions.map(region => {
        return {
          ...region,
          style: POLYGON_STYLES.foreground,
          onClick: async () => {
            await this.selectRegion({ value: region.id })
          }
        }
      })
      this.loading = false
    })
  },
  methods: {
    /**
 * Organizes an array of objects into a folder structure up to maxLevel,
 * based on the "code" field.
 * Then flattens nodes that have only 1 child (skipping the useless intermediate level).
 */
    organizeInFolders(items, maxLevel = 3) {

      /**
       * Function that decides how many tokens to include in the prefix at a certain level.
       * Example of a “growing prefix”:
       *   - level=0 => first 3 tokens
       *   - level=1 => first 4 tokens
       *   - level=2 => first 5 tokens, etc.
       */
      function getPrefixByLevel(code, level) {
        const tokens = code.split(' ').filter(t => t); // filters out any empty strings
        if (tokens.length === 0) return '';

        // Example: the first 3 tokens + level
        const prefixEnd = 3 + level;
        const prefixTokens = tokens.slice(0, prefixEnd);
        return prefixTokens.join(' ');
      }

      /**
       * Recursively groups a set of objects.
       * Returns a node with { code, name, children, leftover }:
       *   - code: the node prefix
       *   - name: a human-readable name (possibly with " - Strada" suffix)
       *   - children: array of child nodes
       *   - leftover: objects that cannot be grouped further at this node
       */
      function createNode(objects, nodeCode, level, maxLevel) {
        // Costruiamo il "nome" a partire da nodeCode
        let currentName = nodeCode;

        // Estrarre gli ultimi due token della stringa
        const lastTwoTokens = nodeCode.split(' ').slice(-2).join(' ');

        // Se gli ultimi due token corrispondono al pattern "S\d+ \d+", aggiungi " - Civico"
        if (lastTwoTokens.match(/^S\d+ \d+$/)) {
          currentName += " - Civico";
          // Se l'ultimo token corrisponde al pattern "S\d+", aggiungi " - Strada"
        } else if (nodeCode.split(' ').slice(-1)[0].match(/^S\d+$/)) {
          currentName += " - Strada";
        } else if (nodeCode.split(' ').slice(-1)[0].match(/^AA\d+$/)) {
          currentName += " - Arredo antico";
        } else if (nodeCode.split(' ').slice(-1)[0].match(/^ST\d+$/)) {
          currentName += " - Struttura";
        }

        const node = {
          code: nodeCode,
          name: currentName,
          children: [],
          leftover: []
        };

        // Se non ci sono oggetti in questo gruppo, nessuna suddivisione
        if (!objects || objects.length === 0) {
          return node;
        }

        // Se abbiamo raggiunto (o superato) il maxLevel, tutti gli oggetti restano nel leftover
        if (level >= maxLevel) {
          node.leftover = objects;
          return node;
        }

        // 1) Raggruppa gli oggetti per il prefisso corrispondente a questo livello
        const groups = {};
        for (const obj of objects) {
          const prefix = getPrefixByLevel(obj.code, level);

          // Se il prefisso è vuoto, l'oggetto finisce nel leftover di questo nodo
          if (!prefix) {
            node.leftover.push(obj);
          } else {
            if (!groups[prefix]) {
              groups[prefix] = [];
            }
            groups[prefix].push(obj);
          }
        }

        // 2) Crea i nodi figli
        for (const prefix in groups) {
          const groupObjs = groups[prefix];
          const childNode = createNode(groupObjs, prefix, level + 1, maxLevel);
          node.children.push(childNode);
        }

        return node;
      }

      /**
       * Post-processing (ricorsivo) che "schiaccia" i nodi intermedi che hanno 1 solo figlio
       * e non hanno leftover, accorpando il figlio direttamente nel padre.
       */
      function flattenSingleChild(node) {
        // Prima di tutto, eseguiamo la stessa operazione sui figli (ricorsione)
        node.children = node.children.map(child => flattenSingleChild(child));

        // Se questo nodo ha esattamente 1 figlio e nessun leftover, possiamo “fondere” quel figlio
        if (node.children.length === 1 && node.leftover.length === 0) {
          const onlyChild = node.children[0];
          // Assorbe i dati del figlio
          node.code = onlyChild.code;
          node.name = onlyChild.name;
          node.leftover = onlyChild.leftover;
          node.children = onlyChild.children;
        }

        return node;
      }

      // 1) Crea la root "fittizia" con code vuoto
      const rootNode = createNode(items, '', 0, maxLevel);

      // 2) "Schiaccia" i nodi che hanno un solo figlio
      const flattened = flattenSingleChild(rootNode);

      return flattened;
    },
    async selectRegion(region) {
      this.currentRegion = this.regions.find(r => r.id === region.value)

      // if no insulae, fetch them
      if (!this.currentRegion.children) {
        this.currentRegion.children = await getInsulae(this.currentRegion.id)
      }

      // set style for insulae
      this.currentRegion.children.forEach(insula => {
        insula.style = POLYGON_STYLES.foreground

        insula.onClick = async () => {
          this.selectInsula({ value: insula.id })
        }
      })

      // set current region style, invisible polygon
      this.currentRegion.style = POLYGON_STYLES.transparent
      this.currentRegion.focus = true
      this.currentRegion.notInteractive = true

      // set other regions style
      this.regions.filter(r => r.id !== this.currentRegion.id).forEach(region => {
        region.style = POLYGON_STYLES.background
      })

      this.currentPolygons = [
        ...this.currentRegion.children,
        this.currentRegion
      ]
      this.currentInsula = null
      this.currentUnity = null
      this.currentEnvironment = null
    },
    async selectInsula(insula) {
      console.log(this.currentRegion)
      this.currentInsula = this.currentRegion.children.find(i => i.id === insula.value)

      if (!this.currentInsula.children) {
        this.currentInsula.children = await getUnities(this.currentInsula.id)
      }

      // set style for unity
      this.currentInsula.children.forEach(unity => {
        unity.style = POLYGON_STYLES.foreground

        unity.onClick = async () => {
          await this.selectUnity(unity.id, 'id')
        }
      })

      // set current insula style, invisible polygon
      this.currentInsula.style = POLYGON_STYLES.transparent
      this.currentInsula.focus = true
      this.currentInsula.notInteractive = true

      // set other insulae style
      this.currentRegion.children.filter(i => i.id !== this.currentInsula.id).forEach(insula => {
        insula.style = POLYGON_STYLES.background
      })

      this.currentPolygons = [
        ...this.currentInsula.children,
        ...this.currentInsula.topographic_element4s,
        this.currentInsula,
        //...this.currentRegion.insula.filter(i => i.topographic_element_id !== this.currentInsula.topographic_element_id), // disabled for now
      ]

      this.currentUnity = null
      this.currentEnvironment = null
    },
    async selectUnity(unityId, parameterType) {
      let currentUnityIndex = null
      if (parameterType == 'id') {
        currentUnityIndex = this.currentInsula.children.findIndex(unity => unity.id === unityId)
      } else {
        currentUnityIndex = unityId
      }

      this.currentUnity = { ...this.currentInsula.children[currentUnityIndex] }


      if (!this.currentUnity.children) {
        this.currentUnity.children = await getEnvironments(this.currentUnity.id)

        // search for current unity in currentInsula and set environments
        this.currentInsula.children = this.currentInsula.children.map(unity => {
          if (unity.id === this.currentUnity.id) {
            unity.children = this.currentUnity.children
          }
          return unity
        })
      }

      // set style for environment
      this.currentUnity.children = this.currentUnity.children?.map(environment => ({
        ...environment,
        style: POLYGON_STYLES.foreground,
        onClick: () => {
          this.selectEnvironment(environment.id, 'id')
        }
      }));

      // set current unity style, invisible polygon
      this.currentUnity = {
        ...this.currentUnity,
        style: POLYGON_STYLES.transparent,
        focus: true,
        notInteractive: true
      }

      // set other unity style
      this.currentInsula.children = this.currentInsula.children?.map(unity =>
        unity.id !== this.currentUnity.id
          ? { ...unity, style: POLYGON_STYLES.background }
          : unity
      );

      this.currentPolygons = [
        ...this.currentUnity.children,
        ...this.currentUnity.topographic_element4s,
        this.currentUnity,
        //...this.currentInsula.unity.filter(unity => unity.topographic_element_id !== this.currentUnity.topographic_element_id), // disabled for now
      ]
      this.currentEnvironment = null
    },
    async selectEnvironment(id, parameterType) {
      let selectedEnvironmentId = null
      let selectedEnvironmentIndex = null
      if (parameterType == 'id') {
        selectedEnvironmentId = id
        selectedEnvironmentIndex = this.currentUnity.children.findIndex(environment => environment.id == selectedEnvironmentId);
      } else {
        selectedEnvironmentIndex = id
        selectedEnvironmentId = this.currentUnity.children[selectedEnvironmentIndex].id
      }

      if (this.currentEnvironment?.id == selectedEnvironmentId) {
        return
      }

      this.currentEnvironment = { ...this.currentUnity.children[selectedEnvironmentIndex] }

      if (!this.currentEnvironment.topographic_element4s) {
        this.currentEnvironment.topographic_element4s = await getTopographicElements4(this.currentEnvironment.id)
      }

      // update style and onClick for topographic elements
      this.currentEnvironment.topographic_element4s = this.currentEnvironment.topographic_element4s.map(topographicElement4 => {
        return {
          ...topographicElement4, style: {
            ...POLYGON_STYLES.foreground,
            weight: 7,
          },
          onClick: () => {
            this.selectTopographicElement(topographicElement4.id)
          }
        }
      })

      // set current environment style, invisible polygon
      this.currentEnvironment.style = POLYGON_STYLES.transparent

      // set other environments style
      this.currentUnity.children.filter(environment => environment.id !== this.currentEnvironment.id).forEach(environment => {
        environment.style = POLYGON_STYLES.background
      })

      // create a copy of topographic_element4s array
      let topographicElement4sCopy = [...this.currentEnvironment.topographic_element4s]
      console.log("topographicElement4sCopy", topographicElement4sCopy)

      // assign order value depending on type. 1 for 'Superficie orizzontale', 100 for 'Superficie verticale', else 50
      topographicElement4sCopy.forEach(element => {
        if (element.type.name === 'Superficie orizzontale') {
          element.order = 1
        } else if (element.type.name === 'Superficie verticale') {
          element.order = 100
        } else {
          element.order = 50
        }
      })

      this.currentPolygons = [
        this.currentEnvironment,
        ...topographicElement4sCopy.sort((a, b) => a.order - b.order)
      ]
    },

    selectTopographicElement(topographicElementId) {
      if (typeof topographicElementId === 'object') {
        this.selectedTopographicElementId = topographicElementId.target.value
      } else {
        this.selectedTopographicElementId = topographicElementId
      }

      // if topElId is not equal to topographicElementId, set topElId to topographicElementId
      if (this.topElId !== this.selectedTopographicElementId) {
        this.topElId = this.selectedTopographicElementId
      }

      let topographicElement4 = this.currentEnvironment?.topographic_element4s.find(element => element.id == this.selectedTopographicElementId) ?? this.currentUnity?.topographic_element4s.find(element => element.id == this.selectedTopographicElementId) ?? this.currentInsula?.topographic_element4s.find(element => element.id == this.selectedTopographicElementId)
        ?? this.currentRegion?.topographic_element4s.find(element => element.id == this.selectedTopographicElementId)

      if (this.currentEnvironment) {
        this.currentEnvironment.topographic_element4s.forEach(element => {
          element.style = POLYGON_STYLES.foreground
        })
        this.currentEnvironment.style = POLYGON_STYLES.transparent
        this.currentEnvironment.focus = true
      }

      topographicElement4.style = POLYGON_STYLES.selected

      if (this.currentUnity) {
        this.currentUnity.children.forEach(environment => {
          environment.style = POLYGON_STYLES.background
        })
      }

      this.currentPolygons = [
        topographicElement4,
        ...(this.currentEnvironment ? [this.currentEnvironment] : []),
        //...this.currentUnity.environments.filter(environment => environment.topographic_element_id !== this.currentEnvironment.topographic_element_id), // disabled for now
      ]
    },
    async filterByLocation(position) {
      this.currentPosition = position
      await this.calculatePolygons()
    },
    async calculatePolygons() {
      let result = []

      let availableUserPosition = this.startingPosition ? this.startingPosition : this.currentPosition

      const startingPosition = availableUserPosition ? geojsonToTurf(this.currentPosition) : null
      let nearestTopographicElement4 = null;

      if (availableUserPosition) {
        try {
          nearestTopographicElement4 = (await getNearestTopographicElement4(startingPosition.geometry.coordinates[0], startingPosition.geometry.coordinates[1])).data
        } catch (error) {
          console.error("Error getting nearest topographic element", error)
        }
      }

      if (nearestTopographicElement4) {
        console.log("nearest", nearestTopographicElement4)
        await this.selectRegion({ value: nearestTopographicElement4.parent.parent.parent.parent.id })
        await this.selectInsula({ value: nearestTopographicElement4.parent.parent.parent.id })
        await this.selectUnity(nearestTopographicElement4.parent.parent.id, 'id')
      } else {
        // if no position is available, return all the polygons
        result = this.polygons
        this.currentPolygons = result
      }

    }
  },
  emits: ['close', 'save'],
  setup(props, { emit }) {
    const loading = ref(true)
    const searchQuery = ref('')
    const currentPosition = ref(null)
    const currentRegion = ref(null)
    const currentInsula = ref(null)
    const currentUnity = ref(null)
    const currentEnvironment = ref(null)
    const selectedTopographicElementId = ref(null)
    const uxstore = useUXStore()
    const topElId = ref()

    // different topographic elements
    const regions = ref([])

    const currentPolygons = ref([])

    const close = () => {
      emit('close')
    }

    const save = () => {
      let topographicElement = currentEnvironment.value?.topographic_element4s?.find(element => element.id == topElId.value) ?? currentUnity.value?.topographic_element4s?.find(element => element.id == topElId.value) ??
        currentInsula.value?.topographic_element4s?.find(element => element.id == topElId.value) ?? currentRegion.value?.topographic_element4s?.find(element => element.id == topElId.value) ?? {}
      topographicElement.environment = currentUnity.value?.children.find(environment => environment.id == topographicElement.topographic_element_id) ?? {}
      topographicElement.environment.unity = currentInsula.value?.children.find(unity => unity.id == topographicElement.environment?.parent_id || unity.id == topographicElement.topographic_element_id) ?? {}
      topographicElement.environment.unity.insula = currentRegion.value.children.find(insula => insula.id == topographicElement.environment?.parent?.parent_id || insula.id == topographicElement.topographic_element_id) ?? {}
      topographicElement.environment.unity.insula.region = regions.value.find(region => region.id == topographicElement.environment?.parent?.parent?.parent_id || region.id == topographicElement.topographic_element_id)

      // simplify the object setting to null some attributes
      topographicElement.environment.topographic_element4s = null
      topographicElement.environment.unity.environments = null
      topographicElement.environment.unity.insula.unity = null
      //topographicElement.environment.unity.insula.region.insula = null


      emit('save', topographicElement)
    }

    onUnmounted(() => {
      uxstore.showOverlay = false

      currentPosition.value = null;
      currentRegion.value = null;
      currentInsula.value = null;
      currentUnity.value = null;
      currentEnvironment.value = null;
      selectedTopographicElementId.value = null;
      regions.value = [];
      currentPolygons.value = [];
    })

    return {
      close, save, loading, searchQuery, props, selectedTopographicElementId, uxstore,
      currentPosition, regions, currentPolygons, MAP_LAYERS, MAP_CENTER,
      currentRegion, currentInsula, currentUnity, currentEnvironment, topElId
    }
  }
}
</script>

<style>
.ref-top {
  height: 100%;
  width: 100%;
  position: absolute;
}

.ref-top .popup {
  position: relative;
  background-color: var(--primary-color);
  height: 80%;
  width: 80%;
  padding: 30px;
  z-index: 6;
}

.ref-top .popup>div:last-child {
  height: 100%;
  overflow: hidden;
}

.ref-top .popup>div:last-child>div:first-child {
  grid-template-rows: max-content max-content;
  overflow: hidden;
}

.ref-top .popup .act-buttons {
  margin: 30px 0 10px 0;
}

.ref-top .popup span.title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.875rem;
  letter-spacing: 0.035rem;
}

.ref-top .popup .act-buttons .v-button {
  background-color: var(--base-dark-color);
  color: var(--primary-color);
  justify-content: center;
  width: 100px;
}

.ref-top-list {
  overflow-y: scroll;
  max-height: 50dvh;
}

.accordion.sub .panel {
  padding-left: 0px;
  display: flex;
  flex-direction: column;
}

.accordion:not(.sub) :is(.title, .panel) {
  display: flex;
  background-color: #D9D9D9;
}

.accordion:not(.sub) .panel {
  background-color: #D9D9D9;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.options-box {
  margin: 10px;
  margin-top: 5px;
}

.options-box .radiobutton {
  background-color: #D9D9D9;
  height: 30px;
  padding: 6px;
}

.ref-top .popup .dropdown :is(.selected, .box) {
  
  width: 100%;
}

.ref-top-option {
  text-align: left;
  display: grid;
  grid-template-columns: 1em 1fr;
}

.sub-accordion {
  background-color: #D9D9D9;
}

.sub-accordion .panel {
  padding-left: 30px;

}
</style>
<template>
  <div :id="id" class="map" :key="id" :style="{
    'cursor': isFlying ? 'wait' : 'grab',
    'pointer-events': isFlying ? 'none' : 'auto'
  }"></div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-compass'
import 'leaflet-compass/dist/leaflet-compass.min.css';
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import { useGeolocation } from '@vueuse/core';
import { watchEffect, ref } from 'vue'
import { throttle } from 'lodash'
import { point, booleanPointInPolygon } from '@turf/turf'
import { geojsonToTurf } from '@/utils/polygons'
import 'leaflet-rotate'

export default {
  props: {
    customLayers: { type: Array, default: () => [] },
    polygons: { type: Array, default: () => [] },
    markers: { type: Array, default: () => [] },
    geolocate: { type: Boolean, default: false },
    zoomControl: { type: Boolean, default: false },
    layerControl: { type: Boolean, default: false },
    locateControl: { type: Boolean, default: false },
    fullscreenControl: { type: Boolean, default: false },
    orientation: { type: Boolean, default: false },
    drawing: { type: Boolean, default: false },
    center: { type: Array, default: () => null },
    fitBounds: { type: Boolean, default: true },
    defaultFitPolygonBounds: { type: Boolean, default: true },
    currentLayer: { type: Number, default: 0 },
    zoomDuration: { type: Number, default: 2 },
    leafletConfig: { type: Object, default: {} },
    minPointsZoom: { type: Number, default: 20 },
  },
  emits: [
    'location',
    'click',
    'draw:created',
    'draw:updated',
    'draw:removed',
    'fullscreen',
    'layer:change',
    'zoomend'
  ],
  data: () => ({
    map: null,
    id: 'map' + Date.now(),
    baseLayers: [],
    leafletMarkers: [],
    drawPinsTimeout: null,
    throttledPolygons: [],
    pointInPolygonCache: new Map(),
    isFlying: false
  }),
  setup() {
    let zoomed = ref(false)
    const { coords, locatedAt, error, resume, pause } = useGeolocation({
      enableHighAccuracy: true
    })
    return { coords, locatedAt, error, resume, pause, zoomed }
  },
  watch: {
    polygons: {
      deep: true,
      handler: throttle(function (newVal) {
        this.throttledPolygons = newVal
        this.drawPolygons(this.polygonsArray)
      }, 300)
    },
    markers: {
      handler: function () {
        if (this.markers.length > 0) {
          clearTimeout(this.drawPinsTimeout);
          this.drawPinsTimeout = setTimeout(() => {
            this.drawPins(this.markers);
          }, 2000);
        }
      },
      deep: true
    },
    currentLayer: {
      handler: function () {
        this.initializeLayers(this.customLayers)
      },
      deep: true
    },
    customLayers: {
      handler: function () {
        this.initializeLayers(this.customLayers)
      },
      deep: true
    }
  },
  methods: {
    polygonMarkerMouseoverCallback(mapReference, layer, marker) {
      // remove old markers
      mapReference.eachLayer(layer => {
        if (layer instanceof L.Marker && layer.source === 'polygon') {
          mapReference.removeLayer(layer)
        }
      })

      if (marker) marker.addTo(mapReference)
    },
    polygonMarkerMouseoutCallback(mapReference, marker) {
      if (marker) mapReference.removeLayer(marker)
    },
    createPolygonMarker(polygon, layer, mapReference) {
      let marker = L.marker([polygon.centroid.geometry.coordinates[1], polygon.centroid.geometry.coordinates[0]], {
        icon: L.divIcon({
          iconSize: "auto",
          html: polygon.marker.html,
        })
      })
      marker.source = 'polygon'

      if (layer) {
        layer.on('mouseover', () => {
          this.polygonMarkerMouseoverCallback(mapReference, layer, marker)
        })
        layer.on('mouseout', () => {
          this.polygonMarkerMouseoutCallback(mapReference, marker)
        })
      }

      return marker
    },
    getKeyForCoordinates(lat, lng, precision) {
      const latRounded = lat.toFixed(precision)
      const lngRounded = lng.toFixed(precision)
      return `${latRounded},${lngRounded}`
    },
    isPointInsidePolygon(lat, lng, polygon, precision = 6) {
      const key = this.getKeyForCoordinates(lat, lng, precision)
      if (this.pointInPolygonCache.has(key)) {
        return this.pointInPolygonCache.get(key)
      }

      let clickedPoint = point([lng, lat]);
      let isInside = booleanPointInPolygon(clickedPoint, polygon);
      this.pointInPolygonCache.set(key, isInside)

      return isInside
    },
    drawMarker(lat, lng, style = null, onClick = null) {
      if (!style) {
        style = { radius: 10, color: "#ffffff", fillColor: "#0066ff", fillOpacity: 1 }
      }
      if (this.marker) {
        this.map.removeLayer(this.marker)
      }
      let marker = L.circleMarker([lat, lng], style).addTo(this.map)
      if (onClick) {
        marker.on('click', onClick)
      }
      return marker
    },
    drawPolygons(polygons) {
      // uuid management for performance optimization
      let newUuidMap = new Map()
      polygons.forEach(polygon => polygon.uuid && newUuidMap.set(polygon.uuid, true))

      // remove old polygons
      this.map?.eachLayer(layer => {
        if (layer instanceof L.GeoJSON && !newUuidMap.has(layer.options.uuid)) {
          this.map.removeLayer(layer)
        }
      })

      console.log('reusing ' + Object.keys(this.map._layers).length + ' layers')

      let uuidMap = new Map()
      this.map?.eachLayer(layer => layer instanceof L.GeoJSON && layer.options.uuid && uuidMap.set(layer.options.uuid, true))
      let mapReference = this.map

      if (polygons.length > 0) {
        polygons.forEach(polygon => {
          if (!polygon.polygon) return;
          if (polygon.polygon.type === 'FeatureCollection' && polygon.polygon.features.length === 0) return;

          let geojson = null;
          if (polygon.uuid && uuidMap.has(polygon.uuid)) {
            // it is taken and added again in order to maintain the correct order
            geojson = Object.values(mapReference._layers).find(layer => layer.options.uuid === polygon.uuid)

            // remove layer from the map
            mapReference.removeLayer(geojson)
          } else {
            geojson = L.geoJson(polygon.polygon, {
              style: polygon.pointLambda ? null : polygon.style,
              interactive: !polygon.notInteractive,
              pointToLayer: function (feature, latlng) {
                let layer = polygon.pointLambda ? polygon.pointLambda(feature, latlng) : L.circleMarker(latlng, polygon.style);
                // manage mouseover and mouseout events for points
                if (polygon.marker) {
                  this.createPolygonMarker(polygon, layer, mapReference)
                }

                // listener
                layer.on('click', function () {
                  if (polygon.onClick) {
                    polygon.onClick(feature)
                  }
                })

                polygon.pointInstance = layer
                return layer;
              },
              onEachFeature: function (feature, layer) {
                // if feature is LineString and has onClick, execute onClick
                if ((feature.type === 'LineString' || feature.type == 'MultiLineString' || feature.type == 'MultiPolygon') && polygon.onClick && !polygon.notInteractive) {
                  layer.on('click', polygon.onClick)
                } else if (this.defaultFitPolygonBounds) {
                  layer.on('click', function () {
                    mapReference.fitBounds(layer.getBounds())
                  })
                }

                if (polygon.onHover) {
                  layer.on('mouseover', polygon.onHover)
                }

                if (polygon.onUnhover) {
                  layer.on('mouseout', polygon.onUnhover)
                }

                if (polygon.hoverStyle) {
                  layer.on('mouseover', function () {
                    layer.setStyle(polygon.hoverStyle)
                  })
                  layer.on('mouseout', function () {
                    try {
                      layer.setStyle(polygon.style)
                    } catch (error) {
                      console.log(error)
                    }
                  })
                }

                if (polygon.tooltip) {
                  // it's possible to have errors with getBounds()
                  try {
                    let tooltip = L.tooltip();
                    tooltip.setContent(polygon.tooltip);
                    // if feature is a point
                    let center = layer.feature.geometry.type === 'Point' ? layer.getLatLng() : layer.getBounds().getCenter();
                    tooltip.setLatLng(center);
                    layer.bindTooltip(tooltip);
                  } catch (error) {
                    console.log(error)
                  }
                }
              },
              uuid: polygon.uuid
            })
            geojson.eachLayer(layer => {
              // if feature is LineString and has onClick, execute onClick
              if (layer.feature.geometry.type === 'LineString' && polygon.onClick) {
                layer.on('click', polygon.onClick)
              }

              if (polygon.marker) {
                this.createPolygonMarker(polygon, layer, mapReference)
              }
            })
          }

          geojson.addTo(this.map);
        })

        setTimeout(() => {
          try {
            // invalidate size on timeout to fix leaflet bug
            this.map.invalidateSize();

            // check if there is a polygon with the focus attribute
            let focusPolygon = polygons.find(polygon => polygon.focus)

            // if there is a polygon with the focus attribute, fit the map to the bounds of that polygon
            if (focusPolygon) {
              let bounds = L.geoJson(focusPolygon.polygon).getBounds();

              if (this.leafletConfig.fitBoundsPadding) {
                bounds = bounds.pad(this.leafletConfig.fitBoundsPadding)
              }

              this.map.flyToBounds(bounds, {
                duration: this.zoomDuration
              });
              this.isFlying = true;
            } else if (this.fitBounds) {
              let allBounds = L.latLngBounds();

              polygons.forEach(polygon => {
                allBounds.extend(L.geoJson(polygon.polygon).getBounds());
              });

              this.map.fitBounds(allBounds);
            }
          } catch (error) {
            console.log(error)
          }
        }, 100)
      }
    },
    drawPins(markers) {
      // remove old markers
      this.leafletMarkers.forEach(marker => {
        this.map.removeLayer(marker)
      })
      this.leafletMarkers = []

      console.log('drawing markers...');

      markers.forEach((marker) => {
        let leafletMarker = L.marker([marker.centroid.geometry.coordinates[1], marker.centroid.geometry.coordinates[0]], {
          icon: L.divIcon({
            iconSize: "auto",
            html: marker.html,
          })
        })

        if (this.map.getZoom() <= marker.options.minZoom) {
          leafletMarker.setOpacity(0)
        }

        leafletMarker.addTo(this.map)

        this.leafletMarkers.push(leafletMarker)
      })
    },
    initializeLayers() {
      // remove old layers
      this.map?.eachLayer(layer => {
        if (layer instanceof L.TileLayer) {
          this.map.removeLayer(layer)
        }
      })

      this.baseLayers = [];

      // foreach custom layer, create a layer group
      this.customLayers.forEach((layer, index) => {
        let group = L.layerGroup();

        // add each layer in the group to the map
        for (let i = 0; i < layer.layers.length; i++) {
          let layerDetails = layer.layers[i];
          let tileLayer = L.tileLayer(layerDetails.url, layerDetails.config);
          tileLayer.addTo(group);

          // If this is the first layer, bring it to the front
          if (i === 0) {
            tileLayer.bringToFront();
          }
        }

        // add the group to the baseLayers
        this.baseLayers.push(group)
      });

      if (Object.keys(this.baseLayers).length > 0) {
        this.baseLayers[this.currentLayer].addTo(this.map);
      }
    }
  },
  computed: {
    polygonsArray() {
      // get polygons and remove empty polygons
      let result = this.throttledPolygons.map(polygon => {
        if (typeof polygon == 'string') {
          return {
            polygon: polygon,
            onClick: null,
            onHover: null,
            onUnhover: null,
            focus: false,
            notInteractive: false,
            style: null,
            hoverStyle: null,
            tooltip: null,
            title: null,
            data: null,
            centroid: null,
            order: null,
            turfPolygon: geojsonToTurf(JSON.parse(polygon)),
            uuid: null
          }
        }

        return {
          polygon: polygon.polygon ? polygon.polygon : null,
          onClick: polygon.onClick,
          onHover: polygon.onHover,
          onUnhover: polygon.onUnhover,
          marker: polygon.marker ? polygon.marker : null,
          pointLambda: polygon.pointLambda ? polygon.pointLambda : null,
          focus: polygon.focus ? polygon.focus : false,
          notInteractive: polygon.notInteractive ? polygon.notInteractive : false,
          style: polygon.style,
          hoverStyle: polygon.hoverStyle ? polygon.hoverStyle : null,
          tooltip: polygon.tooltip ? polygon.tooltip : null,
          title: polygon.title ? polygon.title : null,
          order: polygon.order ? polygon.order : null,
          turfPolygon: polygon.polygon ? geojsonToTurf(polygon.polygon) : null,
          centroid: polygon.centroid ? polygon.centroid : null,
          uuid: polygon.uuid ? polygon.uuid : null,
          data: { ...(polygon.sheet ? polygon.sheet : {}) }
        }
      }).filter(polygon => polygon.polygon)


      // parse polygons and flatten array
      result = result.map(polygon => {
        if (typeof polygon.polygon == 'string') {
          polygon.polygon = JSON.parse(polygon.polygon)
        }

        return polygon
      }).flat()

      // sort polygons by order
      result = result.sort((a, b) => a.order - b.order)

      return result
    }
  },
  mounted() {
    if (this.geolocate) this.resume();

    let container = L.DomUtil.get(this.id);
    if (container != null) {
      container._leaflet_id = null;
    }

    /*if (this.leafletConfig.rotate) {
      import('leaflet-rotate').then(() => {
        console.log('leaflet-rotate loaded')
      })
    }*/

    this.map = L.map(this.id, {
      ...this.leafletConfig,
      center: this.center,
      zoom: 16,
      zoomControl: false,
      minZoom: this.leafletConfig.minZoom ? this.leafletConfig.minZoom : 18,
      maxZoom: this.leafletConfig.maxZoom ? this.leafletConfig.maxZoom : 22,
      rotate: this.leafletConfig.rotate ? this.leafletConfig.rotate : false,
      rotateControl: this.leafletConfig.rotateControl ? this.leafletConfig.rotateControl : {},
      attributionControl: false,
    });

    if (this.orientation) {
      L.control.compass({
        position: 'topright',
        autoActive: true,
        showDigit: false,
      }).addTo(this.map);
    }

    // layer selection
    this.baseLayers = [];

    this.initializeLayers(this.customLayers);

    if (this.fullscreenControl) {
      L.Control.Fullscreen = L.Control.extend({
        onAdd: () => {
          let cont = L.DomUtil.create('div', 'leaflet-bar leaflet-control-fullscreen-box');
          var btn = L.DomUtil.create('a', 'leaflet-control-fullscreen');
          btn.addEventListener('click', () => {
            this.$emit('fullscreen')
          })
          cont.appendChild(btn)
          return cont;
        },
        onRemove: function () { }
      })
      L.Control.fullscreen = function (opts) {
        return new L.Control.Fullscreen(opts);
      }
      L.Control.fullscreen({ position: 'bottomright' }).addTo(this.map);
    }

    if (this.locateControl) {
      L.Control.Geolocate = L.Control.extend({
        onAdd: function () {
          let cont = L.DomUtil.create('div', 'leaflet-bar leaflet-control-geolocate-box');
          var btn = L.DomUtil.create('a', 'leaflet-control-geolocate');
          btn.addEventListener('click', () => {
            console.log('geolocate!!!')
          });
          cont.appendChild(btn)
          return cont;
        },
        onRemove: function () {
        }
      });
      L.Control.geolocate = function (opts) {
        return new L.Control.Geolocate(opts);
      }
      L.Control.geolocate({ position: 'bottomright' }).addTo(this.map);
    }

    if (this.zoomControl) {
      L.control.zoom({
        position: 'bottomright',
        zoomInText: '',
        zoomOutText: ''
      }).addTo(this.map);
    }

    if (this.layerControl) {
      L.control.layers(this.baseLayers, null, {
        collapsed: false,
        position: 'bottomleft'
      }).addTo(this.map);
    }

    this.map.on('baselayerchange', (e) => {
      // emit e.name to integer
      this.$emit('layer:change', parseInt(e.name))
    })

    // leaflet bug
    this.map.invalidateSize();

    this.throttledPolygons = this.polygons

    this.drawPolygons(this.polygonsArray)

    if (this.geolocate) {
      let circle, marker;

      watchEffect(() => {
        let position = [this.coords.latitude, this.coords.longitude]

        // check if position is valid
        if (position[0] == 'Infinity' || position[1] == 'Infinity') return;

        let pointFeature = {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": position
          }
        }
        if (this.coords && this.coords.longitude != 'Infinity') {
          if (marker) {
            this.map.removeLayer(marker)
            this.map.removeLayer(circle)
          }

          marker = this.drawMarker(this.coords.latitude, this.coords.longitude)
          circle = L.circle([this.coords.latitude, this.coords.longitude], {
            radius: this.coords.accuracy,
            fillOpacity: 0.1,
            weight: 1,
            interactive: false
          }).addTo(this.map)

          if (!this.zoomed) {
            //this.map.fitBounds(circle.getBounds())
            this.zoomed = true
            this.$emit('location', pointFeature)
          }
        }
      })
    }

    if (this.drawing) {
      this.map.pm.addControls({
        position: 'topleft',
        drawRectangle: false,
        drawCircleMarker: false,
        drawCircle: false,
        drawText: false
      })

      // the leaflet id is taken in order to identify the polygon
      this.map.on('pm:create', (e) => {
        let geojson = e.layer.toGeoJSON()
        geojson.properties.id = e.layer._leaflet_id
        this.$emit('draw:created', geojson)

        e.layer.on('pm:update', (e) => {
          let geojson = e.layer.toGeoJSON()
          geojson.properties.id = e.layer._leaflet_id
          this.$emit('draw:updated', geojson)
        })

        e.layer.on('pm:cut', (e) => {
          let geojson = e.layer.toGeoJSON()
          geojson.properties.id = e.layer._leaflet_id
          this.$emit('draw:updated', geojson)
        })
      })

      this.map.on('pm:remove', (e) => {
        this.$emit('draw:removed', e.layer._leaflet_id)
      })
    }

    if (this.leafletConfig.bearing && this.map.setBearing) {
      this.map.setBearing(this.leafletConfig.bearing)
    }

    // listeners
    this.map.on('click', (e) => {
      let clickedPoint = point([e.latlng.lng, e.latlng.lat]);
      let intersectedPolygons = [];

      this.polygonsArray.filter(p => !p.notInteractive).forEach(polygon => {
        if (!polygon.polygon || !polygon.turfPolygon) return;

        // check if polygon is not a point
        if (polygon.turfPolygon.geometry.type === 'Point') return;

        let isInside = polygon.turfPolygon.geometry.type === 'MultiLineString' ? false : booleanPointInPolygon(clickedPoint, polygon.turfPolygon);

        if (isInside) {
          intersectedPolygons.push(polygon);
        }
      });

      this.$emit('click', { event: e, polygons: intersectedPolygons });
    });

    this.map.on('zoomend', (e) => {
      // reset isFlying
      this.isFlying = false;

      this.leafletMarkers.forEach(marker => {
        marker.setOpacity(this.map.getZoom() > this.markers[0].options.minZoom ? 1 : 0);
      })

      // manage points visibility depending on zoom level
      if (this.map.getZoom() > this.minPointsZoom) {
        this.polygonsArray.filter(p => p.pointInstance).forEach(p => {
          this.map.addLayer(p.pointInstance)
        })
      } else {
        this.polygonsArray.filter(p => p.pointInstance).forEach(p => {
          this.map.removeLayer(p.pointInstance)
        })
      }

      this.$emit('zoomend', e)
    });
  },
  unmounted() {
    this.pause() //stop geolocation

    if (this.map && this.map.remove) {
      try {
        this.map.off()
        this.map.remove()
      } catch (e) {
        console.log('Unmount Map Error', e)
      }
    }
  },
}
</script>
<style scoped>
.map {
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: #EFEFEF;
}
</style>

<style>
.leaflet-tooltip-pane>* {
  text-align: left;
}
</style>